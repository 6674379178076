.accordion {
	&__item {
		button {
            font-weight: 600;
            box-shadow: none;
			padding: 1rem .25rem;
			color: var(--theme-color-blue);
			background-color: transparent;
			transition: color .25s ease-in-out;
			
			&:not(.collapsed){
                color: var(--theme-color-blue);
				background-color: transparent;
                box-shadow: none;

				&::after {
					background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230592D1'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
				}
			}

			&:focus {
				box-shadow: none;
			}
		}

		.accordion-body {
			padding: 1rem .25rem 0;
		}
	}
}