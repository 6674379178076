.hero {
    &__slider {
        position: relative;
        background-color: rgba(217, 217, 217, 0.3);
        
        @include media-breakpoint-up(md){
            max-height: 860px;
            overflow: hidden;
            background-color: transparent;
        }

        .slick-slide {
            &.slick-active {
                @include media-breakpoint-up(md){
                    .hero__body {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }

    &__slide {
        width: 100%;
    }

    &__img {
        width: 100%;
        height: 360px;
        object-fit: cover;
        object-position: center;

        @include media-breakpoint-up(md){
            height: 860px;
        }
    }

    &__body {
        position: relative;
        display: block;
        text-align: center;
        padding: 1.5rem .75rem 4.5rem;
        color: var(--theme-color-black);
        margin: auto;
        width: 100%;
        transition: opacity .3s ease-in-out, transform .5s ease-in-out;
        transition-delay: .6s;
        
        @include media-breakpoint-up(sm){
            padding: 3rem 2.75rem;
            min-height: 320px;
        }
        @include media-breakpoint-up(md){
            position: absolute;
            text-align: left;
            top: 0;
            bottom: 0;
            right: 2.75rem;
            opacity: 0;
            transform: translateY(50px);
            background-color: rgba(217, 217, 217, 0.3);
            color: $white;
            height: fit-content;
            max-width: 500px;
        }
        @include media-breakpoint-up(xxl){
            right: calc((100% - 1320px) / 2 + .75rem);
        }

        h1 {
            text-transform: uppercase;
            margin-bottom: 1.5rem;
            
            @include media-breakpoint-up(md){
                margin-bottom: 2.5rem;
                text-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
            }
        }
    }

    &__desc {
        line-height: 1.8;
        margin-bottom: 1.5rem;
        
        @include media-breakpoint-up(md){
            margin-bottom: 3rem;
        }
    }
}