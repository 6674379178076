.home-about {
    position: relative;
    overflow: hidden;
    z-index: 5;
    background-repeat: no-repeat;
    background-position: center top;
    
    @include media-breakpoint-up(xxl){
        background-image: url('./../img/svg/about-bg.svg');
    }

    &__img {
        width: 100%;
        max-width: 100%;
        height: 260px;
        object-fit: cover;
        object-position: left;
        
        @include media-breakpoint-up(md){
            height: 360px;
            object-position: center;
        }
        @include media-breakpoint-up(xl){
            width: 570px;
            height: 200px;
        }
    }
}

.home-biznes {
    padding: 2rem 0 3rem;
}

.home-portfolio {
    position: relative;
    background-color: var(--theme-color-black);
    padding: 3rem 0;

    .portfolio__slider {
        .box {
            margin: 0;

            @include media-breakpoint-up(md){
                margin: 0 .75rem;
            }
        }

        .slick-dots {
            bottom: -1.75rem;
        }
    }

    .slick-arrow {
        @include media-breakpoint-down(xl){
            top: auto;
            bottom: 16rem;

            &.slick-prev {
                left: 3.5rem;
            }
            &.slick-next {
                right: 3.5rem;
            }
        }

        @include media-breakpoint-down(md){
            &.slick-prev {
                left: 2.75rem;
            }
            &.slick-next {
                right: 2.75rem;
            }
        }

        @include media-breakpoint-down(sm){
            &.slick-prev {
                left: .75rem;
            }
            &.slick-next {
                right: .75rem;
            }
        }
    }

    .portfolio-prev,
    .portfolio-next {
        &:not(.slick-arrow){
            display: none;
        }
    }
}
