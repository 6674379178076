html {
    --theme-color-black: #272727;
    --theme-color-black-50: #403E3E;
    --theme-color-gray: #D9D9D9;
    --theme-color-gray-30: #ABA7A2;
    --theme-color-gray-40: #8F8F8F;
    --theme-color-blue: #0592D1;
}

$font-prime: "Montserrat", sans-serif;

$headings-font-color: var(--theme-color-black);
$headings-font-weight: 600;

$hr-color: var(--theme-color-gray);
$hr-opacity: 1;

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
// $grid-gutter-width: 30px;
// $grid-columns: 12;
// $grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
// $container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

// $gutter: $grid-gutter-width;
