// Bootstrap
.btn {
    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.form-control {
    font-size: .875rem;
    border: 1px solid var(--theme-color-gray);
    height: 3rem;
    border-radius: 0;

    &:focus,
    &:focus-visible {
        outline: none;
        box-shadow: none;
    }
}

// Wordpress
.alignleft, img.alignleft {
	margin-right: 1.5em;
    float: left;
    max-width: 100%;
    height: auto;
}

.alignright, img.alignright {
	margin-left: 1.5em;
    float: right;
    max-width: 100%;
    height: auto;
}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
    clear: both;
    max-width: 100%;
    height: auto;
}

.alignnone, img.alignnone {
    max-width: 100%;
    height: auto;
}

// Contact Form
.wpcf7 {
    .cf-col {
        margin-bottom: 1.75rem;
    }
    
    .wpcf7-form-control {

        &.wpcf7-not-valid {
            border-color: #dc3545;
        }
    }
    .wpcf7-textarea {
        min-height: 200px;
    }
    
    .wpcf7-not-valid-tip {
        font-size: 12px;
        font-weight: 500;
        margin-top: .25rem;
        text-align: center;
        color: #dc3545;
    }
    
    .wpcf7-response-output {
        font-size: 14px;
        text-align: center;
        margin: 2em auto 1em !important;
        max-width: calc(100% - 1.5rem);
    }

    .wpcf7-submit {
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }
    
    .wpcf7-spinner {
        display: none;
    }
    
    .wpcf7-acceptance {
        color: var(--theme-color-black-50);
    
        .wpcf7-list-item {
            font-size: 14px;
            padding-top: .15rem;
            margin-left: 0 !important;
    
            input[type="checkbox"] + span {
                position: absolute;
                cursor: pointer;
                width: 16px;
                height: 16px;
                left: 0;
                border: 12px solid var(--theme-color-gray-30);
                background-color: var(--theme-color-gray-30);
                border-radius: 2px;
                top: 0;
                transition: all .3s ease-out;
                text-indent: 24px;
                white-space: nowrap;
                line-height: 0px;
                user-select: none;
    
                &:after {
                    content: "";
                    position: absolute;
                    width: 0px;
                    height: 7px;
                    border-bottom: 2px solid #fff;
                    border-left: 2px solid #fff;
                    top: 22%;
                    left: -2px;
                    transform-origin: bottom left;
                    transform: rotate(-45deg);
                    opacity: 0;
                    transition: all .3s ease-out;
                }
            }
    
            input[type="checkbox"]:checked + span {
                border: 12px solid #5a5a5a;
                background-color: #5a5a5a;
    
                &:after {
                    opacity: 1;
                    width: 13px;
                }
            }
    
            input {
                width: 16px;
                height: 16px;
                position: relative;
                top: 3px;
                margin-right: .5rem;
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
    z-index: -999;
}

// Lightbox
.lb-closeContainer {
    display: none;
}

// Slick slider
.slick-arrow {
    position: absolute;
    display: grid;
    place-content: center;
    top: 0;
    left: 1rem;
    bottom: 0;
    margin: auto;
    width: 3rem;
    height: 3rem;
    background-color: rgba(217, 217, 217, 0.5);
    border: none;
    transition: background-color .25s ease-in-out;
    z-index: 9;

    &.slick-next {
        left: auto;
        right: 1rem;
    }

    span {
        font-size: 2.5rem;
        color: $white;
        transition: color .25s ease-in-out;
    }

    &:hover {
        background-color: rgba(217, 217, 217, 0.75);

        span {
            color: var(--theme-color-black);
        }
    }
}

.slick-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: .75rem;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    
    @include media-breakpoint-up(md){
        bottom: 2rem;
    }

    .dot {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
        background-color: var(--theme-color-gray);
        transition: background-color .35s ease-in-out;
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
    
    .slick-active {
        .dot {
            background-color: var(--theme-color-black);
        }
    }
}

