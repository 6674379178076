.section {
    padding: 3rem 0;
    
    &--top {
        padding: 3rem 0 0;
    }

    &--faq {
        margin-bottom: 3rem;
        margin-top: 2rem;
    }

    &__title {
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
    }
}