.breadcrumb {
    flex-wrap: nowrap;
    overflow: auto;
    color: var(--theme-color-gray-30);
    padding: .5rem 0 .25rem;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg){
        flex-wrap: wrap;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }


    .breadcrumb-item {
        display: flex;
        font-size: 0.875rem;
        white-space: nowrap;

        &::before {
            color: var(--theme-color-gray-30);
        }

        a {
            white-space: nowrap;
        }

        span {
            margin-left: 0.25rem;
        }
    }
}