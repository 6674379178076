.cf-cookie-banner {
    position: fixed;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999999999;
    font-size: 13px;
    font-weight: 400;
    background: #fff;
    box-shadow: 0px -4px 8px rgba(0,0,0,0.08);

    button {
        border: 0;
        background-color: transparent;
    }

    &__container {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1rem;
        margin: auto;
        
        @include media-breakpoint-up(md){
            padding: 1rem 3.5rem;
            flex-direction: row;
        }
        @include media-breakpoint-up(xl){
            max-width: 1140px;
            padding: 1rem 2.5rem;
        }
    }

    &__body {
        line-height: 1.4;
        margin-top: .75rem;
        margin-bottom: 1rem;

        a {
            text-decoration: underline;
			
			&:hover {
                color: inherit;
            }
        }

        @include media-breakpoint-up(sm){
            margin-right: 1.5rem;
            margin-left: 1.5rem;
        }
        @include media-breakpoint-up(md){
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    &__button {
        button {
            font-weight: 400;
            padding: 0.5rem 1.5rem;
            border-radius: 2rem;
        }

        &--close {
            @include media-breakpoint-up(md){
                display: none;
            }
        }
    }

    &__icon {
        position: absolute;
        border-radius: 100%;
        top: -2rem;
        padding: .5rem;

        &::before {
            content: '';
            position: absolute;
            display: none;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: #fff;
            opacity: .15;
            z-index: -1;
        }

        @include media-breakpoint-up(md){
            position: relative;
            background-color: transparent !important;
            top: 0;

            &::before {
                display: block;
            }
        }
    }

    &__close {
        position: absolute;
        display: none;
        right: 0.5rem;
        top: 0;
        bottom: 0;
        
        @include media-breakpoint-up(md){
            display: flex;
        }
        @include media-breakpoint-up(xl){
            right: 1rem;
        }
    }
}