.footer {
    padding: 3rem 0;
    background-color: var(--theme-color-black);
    color: $white;
    
    @include media-breakpoint-up(xxl){
        background-color: transparent;
        background-image: url('./../img/svg/footer-bg.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    &__info {
        .__item {
            display: flex;
            align-items: center;
            margin-bottom: 0.75rem;
            line-height: 1.4;

            span {
                margin-right: 0.5rem;
            }
        }
    }

    &__logo {
        display: none;
        max-height: 100px;
        
        @include media-breakpoint-up(lg){
            display: block;
        }
        @include media-breakpoint-up(xxl){
            margin-top: -3rem;
        }
    }

    &__box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 100%;
    }

    &__copyrights {
        width: 100%;
        opacity: .7;
        
        .__dev {
            font-size: .875rem;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        a {
            display: inline-block;
            padding: .2rem 0;
            transition: color .3s ease-in-out;

            &:hover {
                color: var(--theme-color-blue);
            }
        }
    }

    hr {
        opacity: 1;
        width: 80px;
    }
}