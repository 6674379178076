.text-blue {
    color:var(--theme-color-blue) !important;
}

.text-black {
    color: var(--theme-color-black) !important;
}

.bg-gray {
    background-color: #F5F5F5;
}

.fw-medium {
    font-weight: 500;
}

.fw-semi {
    font-weight: 600;
}

.bg-black {
    background-color: var(--theme-color-black);
}

.w-md-auto {
    @include media-breakpoint-up(md){
        width: auto !important;
    }
}

.w-lg-auto {
    @include media-breakpoint-up(lg){
        width: auto !important;
    }
}