.realization {
    &__info {
        font-weight: 500;
        
        div {
            padding: 1.75rem 0;

            &:not(:last-of-type){
                border-bottom: 1px solid var(--theme-color-gray-30);
            }

            span {
                color: var(--theme-color-blue);
            }
        }
    }
}

.isotope {
    min-height: 200px;
    margin-left: -.75rem;
    width: calc(100% + 1.5rem);
    
    &__item {
        width: calc(100% / 2 - 1.5rem);
        margin: 0 .75rem 1.5rem;
        
        &--realization {
            width: calc(100% / 1 - 1.5rem);
            margin: 0 .75rem;
        }

        @include media-breakpoint-up(lg){
            width: calc(100% / 3 - 1.5rem);
        }
    }
}