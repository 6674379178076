.blog-card {
    border-bottom: 1px solid var(--theme-color-gray);
    padding-bottom: 2.25rem; 
    margin-bottom: 2.25rem;

    &__title {
        @extend .h4;
        color: var(--theme-color-blue);
        margin-bottom: 1rem;
    }

    &__date {
        color: var(--theme-color-gray-40);
        margin-bottom: 1rem;
    }

    &__img {
        width: 100%;
        height: 320px;
        margin-bottom: 1.5rem;
        
        @include media-breakpoint-up(sm){
            height: 380px;
        }
        @include media-breakpoint-up(xxl){
            height: 480px;
        }

        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &__desc {
        margin-bottom: 1.5rem;
    }
}