.sidebar {
    
    @include media-breakpoint-up(lg){
        position: sticky;
        top: 3rem;
    }
    @include media-breakpoint-up(xl){
        margin-bottom: 2rem;
    }

    ul {
        padding-left: 1.5rem;
        list-style: none;
        margin: 0;
        list-style-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L1 17' stroke='%230592D1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

        li {
            padding-left: 0.25rem;
            margin-bottom: 0.5rem;

            a {
                transition: color .3s ease-in-out;

                &.active,
                &:hover {
                    color: var(--theme-color-blue);
                }
            }
        }
    }
}