h1, h2 {
    font-size: 2rem;

    @include media-breakpoint-down(sm){
        font-size: 1.5rem;
    }
}

a,
a:hover {
    color: unset;
    text-decoration: none;
}