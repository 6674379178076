.search-form {
    
    .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        height: 100%;
    }

    input,
    input:focus {
        border: none;
        box-shadow: none;
        outline: none;
        height: 100%;
    }

    input::placeholder{
        color: rgb(167, 166, 166);
    }
}