.theme-decor {
    display: inline-block;
    flex-shrink: 0;
    width: 32px;
    height: 38px;
    margin-right: 1.25rem;
    background-image: url('./../img/svg/logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    
    @include media-breakpoint-up(sm){
        width: 50px;
        height: 60px;
    }
}

.counter-box {
    position: relative;
    overflow: hidden;
    padding: 1rem;
    color: $white;
    background-color: var(--theme-color-blue);
    width: 100%;
    min-height: 100px;
    z-index: 9;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 80px;
        border-bottom-width: 42px;
        border-color: transparent rgba(255, 255, 255, 0.15) transparent transparent;
        transition: border-right-width .4s cubic-bezier(0.215, 0.610, 0.355, 1);
        z-index: -1;
    }

    span {
        display: block; 
        line-height: 1.4;

        &:first-child(){
            font-size: 2rem;
        }
    }
}

.group-nav {
    display: flex;
    flex-wrap: wrap;

    button {
        border: 0;
        flex-grow: 1;
        text-align: center;
        color: $white;
        padding: .5rem .75rem;
        background-color: var(--theme-color-black);
        transition: background-color .25s ease-in-out;
        
        @include media-breakpoint-up(sm){
            max-width: 300px;
        }

        &.active,
        &.__all {
            background-color: var(--theme-color-blue);
        }
    }
}

.lightbox-thumb {
    display: block;
    max-width: 100%;
    height: 260px;
    overflow: hidden;
    
    @include media-breakpoint-up(sm){
        height: 380px;
    }

    img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        transition: filter .4s ease-in-out, transform .8s cubic-bezier(0.175, 0.885, 0.32, 1);

        &:hover {
            filter: grayscale(1);
            transform: scale(1.14);
        }
    }
}

.grid-thumb {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    
    @include media-breakpoint-up(sm){
        gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    a {
        display: block;
        
        img {
            object-position: center;
            object-fit: cover;
            width: 100%;
            height: 180px;
        }

        &:first-of-type(){
            grid-column: span 2;
            height: 100%;
            
            @include media-breakpoint-up(sm){
                grid-column: auto;
                grid-row: span 2;

                img {
                    height: 384px;
                }
            }
        }
    }
}

.contact-box {
    display: grid;
    margin-bottom: 1.5rem;
    grid-template-columns: 120px auto;
    gap: 1rem;

    &__img {
        height: 120px;
        width: 120px;
        object-fit: cover;
        object-position: center;
    }

    &__body {
        .__name {
            font-weight: 600;
            color: var(--theme-color-blue);
        }
        .__email {
            color: var(--theme-color-blue);
        }
    }
}