.box {
    display: block;
    margin: 0 auto 2.5rem;
    overflow: hidden;
    max-width: 720px;

    &__img {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 240px;
        transition: filter .25s ease-in-out, transform .5s ease-in-out;
        
        @include media-breakpoint-up(sm){
            height: 270px;
        }
    }

    &__title,
    &__subtitle {
        position: relative;
        display: grid;
        place-content: center left;
        text-align: left;
        line-height: 1.2;
        color: $white;
        background-color: var(--theme-color-blue);
        text-transform: none;
        padding: .5rem .75rem;
        width: 100%;
        min-height: 60px;
        z-index: 5;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-top-width: 0;
            border-left-width: 0;
            border-right-width: 80px;
            border-bottom-width: 42px;
            border-color: transparent rgba(255, 255, 255, 0.15) transparent transparent;
            transition: border-right-width .4s cubic-bezier(0.215, 0.610, 0.355, 1);
            z-index: -1;
        }
    }

    &__subtitle {
        background-color: transparent;
    }

    &--dark &__subtitle {
        color: var(--theme-color-black);

        &::before {
            border-color: transparent rgba(234, 234, 234, 0.5) transparent transparent;
        }
    }

    &:hover &__img {
        transform: scale(1.14) rotate(5deg);
        filter: grayscale(1);
    }
}