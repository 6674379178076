.btn {
    position: relative;
    font-size: 1rem;
    padding: .65rem 1.65rem;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    color: $white;
    background-color: var(--theme-color-blue);
    overflow: hidden;
    transition: background-color .45s cubic-bezier(0.645, 0.045, 0.655, 1);
    z-index: 9;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 80px;
        border-bottom-width: 42px;
        border-color: transparent rgba(255, 255, 255, 0.15) transparent transparent;
        transition: border-right-width .4s cubic-bezier(0.215, 0.610, 0.355, 1);
        z-index: -1;
    }

    &:hover {
        color: $white;
        background-color: var(--theme-color-black);

        &::before {
            border-right-width: 0;
        }
    }

    &--black {
        background-color: var(--theme-color-black);

        &:hover {
            background-color: var(--theme-color-blue);
        }
    }
}