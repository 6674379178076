.pagination {
    display: flex;
    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
    
    @include media-breakpoint-up(xl){
        margin: 0 0 1.5rem;
    }

    .page-item {
        display: grid;
        place-content: center;
        width: 2.75rem;
        height: 2.75rem;
        color: $white;
        background-color: var(--theme-color-black);

        .page-link {
            border-radius: 0;
            padding: .5rem;
        }

        path {
            fill: $white;
        }

        &.active {
            background-color: var(--theme-color-blue);
        }
    }
}