.titlebar {
    position: relative;
    overflow: hidden;
    z-index: 9;
    
    &__img {
        width: 100%;
        height: 280px;
        margin-bottom: 2rem;
        
        @include media-breakpoint-up(sm){
            height: 400px;
        }

        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }
}