.navbar {
    position: absolute;
    width: 100%;
    z-index: 999;
    padding: 0;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: $white;
        z-index: -1;
   
        @include media-breakpoint-up(xl){
            width: calc(100vw - (100vw - 100%) - 310px - 2.75rem);
            background-color: rgba(255, 255, 255, 0.9);
        }
        @include media-breakpoint-up(xxl){
            width: calc(100% - (100% - 1320px)/2 - 310px - .75rem);
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: calc((100% - 1320px)/2 + 8rem);
        height: 40px;
        top: 0;
        right: 0;
        background-color: var(--theme-color-black);
        z-index: -1;

        @include media-breakpoint-down(xl){
            display: none;
        }
    }

    .navbar-brand {
        padding: 0;
        margin: 0;

        img {
            max-width: 160px;
        }
        
        @include media-breakpoint-up(sm){
            img {
                max-width: 180px;
            }
        }

        @include media-breakpoint-up(xl){
            img {
                max-width: initial;
            }
        }
    }

    .navbar-toggler {
        border-radius: 0;

        .navbar-toggler-icon {
            width: 40px;
            height: auto;

            &::before,
            &::after, 
            span {
                content: "";
                display: block;
                height: 3px;
                border-radius: 3px;
                margin: 11px 0;
                transition: 0.5s;
                background: var(--theme-color-black);
            }
        }
        
        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:not(.collapsed){
            .navbar-toggler-icon {
                &::before {
                    transform: translateY(15px) rotate(135deg);
                }
                &::after {
                    transform: translateY(-12px) rotate(-135deg);
                }
                span {
                    transform: scale(0);
                }
            }
        }
    }

    .navbar-mobile {
        display: flex;
        font-size: .875rem;
        align-items: center;
        
        .__langs {
            font-weight: 500;
            margin-right: 2rem;
        }

        @include media-breakpoint-up(xl){
            display: none;
        }
    }

    .navbar-collapse {
        flex-direction: column;
        align-items: unset;

        @include media-breakpoint-down(xl){
            position: absolute;
            display: flex;
            flex-direction: column-reverse;
            height: 0;
            left: 0;
            top: 90px;
            width: 100%;
            background-color: $white;
            overflow: hidden;

            &.show {
                height: calc(100vh - 90px);
            }
        }
        @include media-breakpoint-down(sm){
            top: 80px;
            
            &.show {
                height: calc(100vh - 80px);
            }
        }

        .navbar-prenav {
            display: flex;
            line-height: 1.2;
            height: 40px;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;
            font-size: .75rem;
            color: $white;
            background-color: var(--theme-color-black);
            
            @include media-breakpoint-down(xl){
                padding: 1rem 0 0;
                flex-direction: column;
                height: auto;
            }
            @include media-breakpoint-up(xxl){
                gap: 2rem;
                font-size: .8rem;
            }

            .__item {
                display: inline-flex;
                align-items: center;
            }

            .__socials {
                display: none;
                align-items: center;
                gap: .75rem;
                
                @include media-breakpoint-up(xl){
                    display: flex;
                }
            }

            .__langs {
                display: none;

                @include media-breakpoint-up(xl){
                    display: block;
                }
            }

            .__search {
                width: 140px;
                height: 100%;

                form {
                    width: 100%;
                    height: 100%;
                }

                @include media-breakpoint-down(xl){
                    width: 100%;
                    height: 3rem;
                }
                
                input {
                    font-size: .8rem;
                    border: 0;
                    color: $white;
                    padding: .25rem .5rem;
                    height: 100%;
                    background-color: #403E3E;

                    &::-webkit-search-cancel-button{
                        -webkit-appearance:none;
                    }

                    &::placeholder {
                        color: $white;
                    }
                }
            }

            .material-symbols-outlined {
                font-size: 16px;
                margin-right: 0.35rem;
            }
        }

        .navbar-nav {
            padding-top: 1.75rem;
            flex-grow: 1;
            align-items: center;
            gap: .75rem;
            overflow-x: scroll;
            
            @include media-breakpoint-up(xl){
                padding-top: 0;
                gap: 1.75rem;
                margin-left: auto;
                height: 115px;
                overflow-x: hidden
            }

            .menu-item {
                > a {
                    @extend .nav-link;
                    font-size: .85rem;
                    text-transform: uppercase;
                    color: var(--theme-color-black);

                    @include media-breakpoint-up(xxl){
                        font-size: 1rem;
                    }

                    &:active,
                    &:hover {
                        color: var(--theme-color-blue);
                    }
                }

                &:last-of-type(),
                &--blue {
                    @extend .btn;
                    background-color: transparent;
                    
                    a {
                        font-weight: 600;
                        color: var(--theme-color-blue);
                    }
                    
                    @include media-breakpoint-up(xl){
                        background-color: var(--theme-color-blue);
                        padding: 0;
                        width: 140px;
                        height: 115px;
                        
                        a {
                            display: inline-flex;
                            font-weight: 400;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            color: $white;
    
                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}