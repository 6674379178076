.cf-popup {
    display: none;
    @extend .modal;
    
    &__dialog {
        display: flex;
        max-width: 640px;
        @extend .modal-dialog;
        margin: 1rem .5rem;
        min-height: calc(100% - 2rem);

        --body-popup-color: #fefeff;
        --text-popup-color: #1c1e2a;
        
        &--dark {
            --body-popup-color: #1c1e2a;
            --text-popup-color: #ffffff;
        }

        &--navy {
            --body-popup-color: #222746;
            --text-popup-color: #ffffff;
        }

        &--green {
            --body-popup-color: #1b5407;
            --text-popup-color: #ffffff;
        }

        &--center {
            align-items: center;
        }

        &--top {
            align-items: flex-start;
        }

        &--bottom {
            align-items: flex-end;
        }

        @include media-breakpoint-up(sm){
            margin: 2.5rem auto;
            min-height: calc(100% - 5rem);
        }
    }

    &__content {
        @extend .modal-content;
        font-size: 14px;
        border-radius: .5rem;
        border: 0;
        overflow: hidden;
        background-color: var(--body-popup-color);
        color: var(--text-popup-color);
        box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.45);
    }

    &__body {
        padding: 1rem 1.5rem 1.75rem;
    }

    &__header {
        position: relative;
        font-size: 1.125rem;
        padding: 1.25rem 1.5rem .5rem;
        font-weight: 500;
        z-index: 4;
    }

    &__bar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.5rem 0;
    }

    &__icon {
        width: 30px;
        height: 30px;

        svg {
            max-width: 100%;
            height: auto;

            path {
                fill: var(--text-popup-color);
            }
        }
    }

    &__title {
        color: var(--text-popup-color);
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: flex-end;
        text-align: center;
        margin-top: 1.5rem;
    }

    &__button {
        a {
            display: inline-block;
            font-weight: 500;
            border: 2px solid var(--text-popup-color);
            min-width: 130px;
            padding: 0.45rem 1.35rem;
            border-radius: 2rem;
            color: var(--body-popup-color);
            background-color: var(--text-popup-color);
            transition: background-color .3s ease-in-out, color .3s ease-in-out;

            &:hover {
                background-color: transparent;
                color: var(--text-popup-color);
            }

        }
    }

    &__close {
        line-height: 12px;
        border: none;
        width: 32px;
        height: 32px;
        border-radius: .25rem;
        background-color: rgba(255, 255, 255, 0.3);

        path {
            fill: var(--text-popup-color);
        }
    }

}