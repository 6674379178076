.gallery {
	display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    margin: 1.5rem 0;

	@include media-breakpoint-up(md){
		grid-template-columns: repeat(3, 1fr);
	}

	&__img {
		position: relative;
		display: block;
		max-width: 100%;
		height: 170px;
		z-index: 9;
		
		@include media-breakpoint-up(sm){
			height: 220px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__desc {
		position: absolute;
		font-size: .875rem;
		text-align: center;
		bottom: 0;
		width: 100%;
		padding: .5rem .75rem;
		background-color: rgba(36, 36, 36, 0.7);
		color: $white;
	}
}

.content {
	b, strong{
		font-weight: 500;
		color: var(--theme-color-blue);
	}

	a {
		font-weight: 500;
		color: var(--theme-color-blue);
	}

	ul {
        padding-left: 1.5rem;
        list-style: none;
        margin: 0;
        list-style-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L1 17' stroke='%230592D1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

        li {
            padding-left: 0.25rem;
            margin-bottom: 0.5rem;

            a {
                transition: color .3s ease-in-out;

                &.active,
                &:hover {
                    color: var(--theme-color-blue);
                }
            }
        }
    }

	iframe {
		display: block;
		margin: 1rem auto 0;
		max-width: 100%;
		width: 650px;

		@include media-breakpoint-up(md){
			height: 340px;
		}
	}
}